import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import quote from '../../images/new/animations/orangequote.svg';
import blakeQuotePhoto from '../../images/careers/people/blake-quote-photo.png';

const Section7 = () => (
  <div className="section-7">
    <div className="blake-quote">
      <div className="name-section">
        <img className="quote" src={quote} alt="quote" />
        <h4>Blake Fletcher</h4>
        <h5><FormattedMessage id="careers.blakeRole" defaultMessage="Software Engineer" /></h5>
      </div>
      <div className="quote-section">
        <FormattedHTMLMessage tagName="p" id="careers.differenceComesFirst" defaultMessage="<span class='regular'>“At Inkblot, it’s clear that making a difference comes first.</span>" />
        <p><FormattedMessage id="careers.wereAllAffected" defaultMessage="We’re all affected by mental health in different ways, and anytime I hear a story related to mental health, I feel good knowing we’re working hard to solve a meaningful problem." /></p>
        <p><FormattedMessage id="careers.greatOpportunity" defaultMessage="Working at Inkblot provides a great opportunity to do challenging work, with great people, all the while having flexible hours and a great work culture.”" /></p>
      </div>
      <img className="person-photo" src={blakeQuotePhoto} alt="Making a difference" />
    </div>
    {/* <div className="button">
      <button className="dark-blue-button">
        <FormattedMessage
          id="careers.availablePositions"
          defaultMessage="See Our Available Positions"
        />
      </button>
    </div> */}
  </div>
);


Section7.propTypes = {};

export default Section7;
