import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import peopleSlideshow from '../../images/careers/people/people-slideshow.png';

const SectionOne = () => (
  <div className="section-1">
    <div className="header">
      <h1>
        <FormattedMessage id="careers.careerAndTeam" defaultMessage="A career & team you'll love." />
      </h1>
      <FormattedHTMLMessage
        tagName="p"
        id="careers.wereLooking"
        defaultMessage="We’re looking for motivated professionals who want to build something awesome and make a difference."
      />
    </div>
    <div className="image-wrapper">
      <img src={peopleSlideshow} alt="People slideshow" />
    </div>
  </div>
);

export default SectionOne;
