import Arash from '../../../images/careers/people/arash.png';
import Luke from '../../../images/careers/people/luke.png';
import Julie from '../../../images/careers/people/julie.png';
import Nicholas from '../../../images/careers/people/nick.png';
import Alex from '../../../images/careers/people/alex.png';
import Adam from '../../../images/careers/people/adam.png';
import Jacquie from '../../../images/careers/people/jacquie.png';
import Etio from '../../../images/careers/people/etio.png';
import Marion from '../../../images/careers/people/marion.png';
import Ben from '../../../images/careers/people/ben.png';
import Simon from '../../../images/careers/people/simon.png';
import Blake from '../../../images/careers/people/blake.png';
import Eduardo from '../../../images/careers/people/eduardo.png';

import Greg from '../../../images/careers/people/greg.png';
import Eda from '../../../images/careers/people/eda.png';
import Mira from '../../../images/careers/people/mira.png';
import Josh from '../../../images/careers/people/josh.png';
import Brendan from '../../../images/careers/people/brendan.png';
import Yiwen from '../../../images/careers/people/yiwen.png';

import Calla from '../../../images/careers/people/calla.png';
import Emma from '../../../images/careers/people/emma.png';
import Erin from '../../../images/careers/people/erin.png';
import Gaby from '../../../images/careers/people/gaby.png';
import Juliana from '../../../images/careers/people/juliana.png';
import JulieLafleche from '../../../images/careers/people/julie_lafleche.png';
import Owen from '../../../images/careers/people/owen.png';
import Rachel from '../../../images/careers/people/rachel.png';
import Safa from '../../../images/careers/people/safa.png';
import Siva from '../../../images/careers/people/siva.png';

export const leadershipData = [
  {
    id: 2,
    name: 'Luke Vigeant',
    locale: 'luke',
    role: 'Co-Founder & CeO',
    image: Luke,
    email: 'luke@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/vigeant/',
  },
  {
    id: 3,
    name: 'Julie Sabine',
    locale: 'jules',
    role: 'Head of Clinical Services',
    image: Julie,
    email: 'julie@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/juliesabine/',
  },
  {
    id: 4,
    name: 'Marion Yoshida',
    locale: 'marion',
    role: 'Chief Revenue Officer',
    image: Marion,
    image_funny: Marion,
    email: 'marion@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/marionyoshida/',
  },
  {
    id: 5,
    name: 'Gaby Fisch',
    locale: 'gaby',
    role: 'Chief People Officer',
    image: Gaby,
    email: 'gaby@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/gabyfisch/',
  },
];

export const businessData = [
  {
    id: 1,
    name: 'Greg Van Slyke',
    locale: 'greg',
    role: 'Director, Business Development',
    image: Greg,
    email: 'greg@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/gregvanslyke/',
  },
  {
    id: 2,
    name: 'Mira Levine',
    locale: 'mira',
    role: 'Client Manager',
    image: Mira,
    email: 'mira@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/mira-levine-b7b6aba4/',
  },
  {
    id: 3,
    name: 'Eda Shere',
    locale: 'eda',
    role: 'Client Manager',
    image: Eda,
    email: 'eda@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/eda-shere-2446b312/',
  },
  {
    id: 4,
    name: 'Brendan Herjavec',
    locale: 'brendan',
    role: 'Client Manager',
    image: Brendan,
    email: 'brendan@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/brendanherjavec/',
  },
  {
    id: 5,
    name: 'Alex Vigeant',
    locale: 'alex',
    role: 'Operations and Marketing Manager',
    image: Alex,
    email: 'alex@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/alexvigeant/',
  },
  {
    id: 6,
    name: 'Josh Le',
    locale: 'josh',
    role: 'Operations and Marketing Manager',
    image: Josh,
    email: 'josh@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/josh-le/',
  },
  {
    id: 7,
    name: 'Rachel Duan',
    locale: 'rachel',
    role: 'Client Coordinator',
    image: Rachel,
    email: 'rachel.duan@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/rachel-duan/',
  },
];

export const clinicalData = [
  {
    id: 1,
    name: 'Julie Lafleche',
    locale: 'julieLafleche',
    role: 'Clinical Services Specialist',
    image: JulieLafleche,
    email: 'julie.lafleche@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/julie-lafleche-72261b55/',
  },
  {
    id: 2,
    name: 'Emma Esselink',
    locale: 'emma',
    role: 'Clinical Services Specialist',
    image: Emma,
    email: 'emma@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/emma-esselink-bsw-mpa-011523b3/',
  },
  {
    id: 3,
    name: 'Safa Yahya',
    locale: 'safa',
    role: 'Clinical Services Coordinator',
    image: Safa,
    email: 'safa@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/safa-yahya-62351b146/',
  },
  {
    id: 4,
    name: 'Erin Zohar',
    locale: 'erin',
    role: 'Clinical Services Coordinator',
    image: Erin,
    email: 'erin@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/erin-zohar-a3a383155/',
  },
];

export const customerData = [
  {
    id: 1,
    name: 'Eduardo Vonllem',
    locale: 'eddy',
    role: 'Customer Support Manager',
    image: Eduardo,
    email: 'eduardo@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/eduardo-v-6a6273182/',
  },
  {
    id: 2,
    name: 'Calla McLachlan',
    locale: 'calla',
    role: 'Bilingual Customer Care Specialist',
    image: Calla,
    email: 'calla@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/callamclachlan/',
  },
  {
    id: 3,
    name: 'Juliana Varela',
    locale: 'juliana',
    role: 'Bilingual Customer Care Specialist',
    image: Juliana,
    email: 'juliana@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/julianavm/',
  },
];

export const productData = [
  {
    id: 1,
    name: 'Yiwen Sim',
    locale: 'yiwen',
    role: 'Director of product management',
    image: Yiwen,
    email: 'yiwen@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/yiwensim/',
  },

  {
    id: 2,
    name: 'Adam Sweeney',
    locale: 'adam',
    role: 'Technical Director',
    image: Adam,
    email: 'adam@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/asweeney75/',
  },
  {
    id: 3,
    name: 'Jacquie Wortley',
    locale: 'jacquie',
    role: 'Director of product design',
    image: Jacquie,
    email: 'jacquie@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/jacquelinewortley/',
  },
  {
    id: 4,
    name: 'Nicholas Hassan',
    locale: 'nick',
    role: 'Data Scientist',
    image: Nicholas,
    email: 'nicholas@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/nicholas-hassan-263b1897/',
  },
  {
    id: 5,
    name: 'Etio Tan',
    locale: 'etio',
    role: 'Software Engineer',
    image: Etio,
    email: 'etio@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/etio-tan-9950a562/',
  },
  {
    id: 6,
    name: 'Ben Verna-Asals',
    locale: 'ben',
    role: 'Software Engineer',
    image: Ben,
    email: 'ben@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/benjamin-verna-asals-5aba3817a/',
  },
  {
    id: 7,
    name: 'Simon Park',
    locale: 'simon',
    role: 'Software Engineer',
    image: Simon,
    email: 'simon@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/simon-sungil-park/',
  },
  {
    id: 8,
    name: 'Blake Fletcher',
    locale: 'blake',
    role: 'Software Engineer',
    image: Blake,
    email: 'blake@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/blkfltchr/',
  },
  {
    id: 9,
    name: 'Owen Adley',
    locale: 'owen',
    role: 'Front-End Mobile Developer',
    image: Owen,
    email: 'owen@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/owenadley/',
  },
  {
    id: 10,
    name: 'Siva Teja Chittamuru',
    locale: 'siva',
    role: 'Quality Assurance Engineer',
    image: Siva,
    email: 'siva@inkblottherapy.com',
    linkedIn: 'https://www.linkedin.com/in/siva-chittamuru-720801128/',
  },
];
